/* eslint-disable @typescript-eslint/naming-convention */
export enum EPaymentEvents {
  DEAL_CLICKED = 'Payment - Deal Clicked',
  PAYMENT_COMPLETED = 'Payment - Payment Completed',
  PAYMENT_FINISHED = 'Payment - Payment Finished',
  PAYMENT_OPEN = 'Payment - Payment Opened',
  PAYMENT_STARTED = 'Payment - Payment Started',
  PRESET_BUTTON_CLICKED = 'Payment - Preset Button Clicked',
  PROMO_ACTIVATED = 'Payment - Promo Activated',
}

export interface IPaymentPaymentCompletedEvent {
  '$revenue': number;
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPaymentFinishedEvent {
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPaymentOpenEvent {
  buttonPlace: string;
}

export interface IPaymentPaymentStartedEvent {
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPresetButtonClickedEvent {
  'Button Value': number;
}

export interface IPaymentPromoActivatedEvent {
  promoName?: string;
  promoType: string;
}
